import React from 'react';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Testimonial from '../components/Testimonial';

import heroImage from '../images/testimonials/hero.jpg';

const TestimonialsPage = () => (
    <Layout>
        <Hero title="Testimonials" image={heroImage} />
        <Testimonial
            isReverse
            author="Glenn"
            detail="Post-operative rehabilitation for Femoroacetabular impingement"
        >
            I was first referred to Alex after a hip arthroscopy, I was on crutches for six weeks
            and had to begin my recovery. Alex was really keen and took a lot of time and care to
            insure i reached back to my best in fitness and mobility I have always been a highly
            active person and teaching karate was high on my priorities, I really felt{' '}
            <strong>Alex took the time before each appointment with me</strong> to study the best
            techniques for me and put together a personal plan that suited me. I felt that not only
            was he there for me throughout but really took a personal interest in my wellbeing and
            health. <strong>I would recommend anyone to visit Alex</strong> if required as he can
            help you like he did me. Thanks Alex!
        </Testimonial>
        <Testimonial
            author="William Wayland"
            detail="Head Strength & Conditioning Coach, Powering Through Performance"
        >
            Alex has been an associate Physiotherapist at Powering Through Performance since we
            opened our doors. I&#39;ve learned from Alex and his approach is as performance
            discerning as my own, and is not like other Physio&#39;s. I would{' '}
            <strong>trust any of the elite performers and general population clients</strong> I work
            with in the hands of such a <strong>hard working and honest physiotherapist</strong>.
        </Testimonial>
        <Testimonial isReverse author="Adam" detail="Knee pain with resistance training">
            I came to Alex regarding chronic knee pain when going about my day with it being worst
            when climbing stairs. At the age of 24 I thought this is ridiculous as I try to keep fit
            and love lifting weights. <strong>He went way beyond the call of duty</strong>,
            introduced me to a range of new exercises which are now a staple part of my programming.
            I have thrived from the results they give me. He taught me so much about how my body
            ticks and for 6 weeks now my knee pain is almost nil and i honestly feel stronger than
            ever. <strong>Can&#39;t thank him enough</strong>.
        </Testimonial>
        <Testimonial author="Sam" detail="Low back and hip pain">
            I felt an immediately noticeable difference in my hip (less acute pain) and my back
            (less tension) over the last day and a half with the exercises provided. The written
            feedback and recommendations are - by far -{' '}
            <strong>the most helpful and detailed I&#39;ve had from anyone</strong> I&#39;ve worked
            with on my back.
        </Testimonial>
    </Layout>
);

export default TestimonialsPage;
