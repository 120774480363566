import React from 'react';
import PropTypes from 'prop-types';

import Section from '../Section';

import styles from './Testimonial.module.scss';

const Testimonial = ({ author, detail, isReverse, children }) => (
    <Section background={isReverse ? 'xlight' : null} hasContent={false} isFullWidth>
        <i className={styles.icon} />
        <blockquote className={styles.quote}>{children}</blockquote>
        <p className={styles.author}>
            <span className={styles.authorName}>{author}</span>
            <span className={styles.authorDetail}>{detail}</span>
        </p>
    </Section>
);

Testimonial.propTypes = {
    author: PropTypes.string.isRequired,
    detail: PropTypes.string.isRequired,
    isReverse: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

Testimonial.defaultProps = {
    isReverse: false,
};

export default Testimonial;
